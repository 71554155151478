













































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import TopBar from '@/components/organisms/o-top-bar.vue'
import store from '@/store'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'TeachingTool',
  components: {
    TopBar,
    CancelUpdateDialog: () => import('../../../components/cancel-update-dialog.vue'),
    DateField: () => import('@/components/base/DateField.vue'),
    SelectArea: () => import('@/components/molecules/select/area.vue'),
  },
  data () {
    return {
      title: this.$t('teaching_tool.title'),
      errors: [],
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields('teachingToolForm', [
      'teachingTool.name',
      'teachingTool.reference',
      'teachingTool.area',
      'teachingTool.brand',
      'teachingTool.serialNumber',
      'teachingTool.purchaseDate',
      'teachingTool.commentary',
    ]),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('teachingToolForm', {
      isTeachingToolSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
      teachingTool: 'teachingTool',
    }),
    ...mapGetters('navigation', {
      returnPreviousRoute: 'getReturnPreviousRoute',
      getLastRouteLogisticSession: 'getLastRouteLogisticSession',
    }),
    idTeachingTool () {
      return this.$route.params.idTeachingTool || null
    },
  },
  async created () {
    await this.init()
  },
  watch: {
    idTeachingTool () {
      this.init()
    },
  },
  methods: {
    async init () {
      if (this.idTeachingTool) {
        await this.loadTeachingToolById(this.idTeachingTool)
      } else {
        this.reset()
      }
    },
    ...mapActions('teachingToolForm', {
      save: 'save',
      reset: 'reset',
      loadTeachingToolById: 'loadById',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      addTeachingToolToLogisticSession: 'addTeachingTool',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('teaching_tool.form.saved')
        if (this.getLastRouteLogisticSession) {
          this.addTeachingToolToLogisticSession(this.teachingTool)
        }
        this.returnPrevious()
      }
    },
    returnPrevious (): void {
      if (this.returnPreviousRoute) {
        this.$router.push(this.returnPreviousRoute as RawLocation)
      } else {
        this.$router.push({ name: 'TeachingTool List' })
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    store.commit('navigation/removeRoute')
    next()
  },
})
