var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.returnPrevious()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"teachingToolForm","color":"primary","type":"submit","loading":_vm.isTeachingToolSubmitted}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")])],1)],2),_c('v-container',{staticClass:"h-100",attrs:{"id":"teaching_tool-list","fluid":"","tag":"section"}},[[_c('div',[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"teachingToolForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"teaching_tool","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-map-marker"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('teaching_tool.form.title'))+" ")])],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('teaching_tool.form.name'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('teaching_tool.form.name'),"outlined":"","name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('teaching_tool.form.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('teaching_tool.form.reference') + '*',"outlined":""},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('teaching_tool.form.brand'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('teaching_tool.form.brand'),"outlined":"","name":"name"},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('teaching_tool.form.serial_number'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"label":_vm.$t('teaching_tool.form.serial_number'),"outlined":""},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"md-2 ml-3"},[_c('DateField',{attrs:{"rules":"","label":_vm.$t('teaching_tool.form.purchase_date')},model:{value:(_vm.purchaseDate),callback:function ($$v) {_vm.purchaseDate=$$v},expression:"purchaseDate"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('SelectArea',{attrs:{"items":_vm.listAreas,"label":_vm.$t('teaching_tool.form.area'),"outlined":"","classes":"ml-3 mb-4"},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"md-2 ml-3"},[_c('v-textarea',{attrs:{"label":_vm.$t('form.comment'),"outlined":""},model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})],1)],1)],1)],1)],2)],1)],1),_c('cancel-update-dialog',{attrs:{"saving":_vm.isTeachingToolSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }